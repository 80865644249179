// [From](https://github.com/jgallen23/cookie-monster/blob/master/dist/cookie-monster.js)
import { pushEvent, uuid } from './event';

export function getCookie(name) {
  let nameEQ = name + '=',
    ca = document.cookie.split(';'),
    value = '',
    firstChar = '',
    parsed = {},
    c,
    i;

  for (i = 0; i < ca.length; i++) {
    c = ca[i];

    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      value = decodeURIComponent(c.substring(nameEQ.length, c.length));
      firstChar = value.substring(0, 1);

      if (firstChar == '{') {
        try {
          parsed = JSON.parse(value);
          if ('v' in parsed) {
            return parsed.v;
          }
        } catch (e) {
          return value;
        }
      }

      if (value == 'undefined') {
        return undefined;
      }

      return value;
    }
  }
  return null;
}

export function getMachineId() {
  let merged = {};

  window.dataLayer.forEach(item => (merged = Object.assign(merged, item)));
  const privacySettings = merged.privacySettings;
  let machineId;

  if (privacySettings && privacySettings.experience) {
    machineId = getCookie('m');
    if (!machineId) {
      const oneYear = 31536000;

      machineId = uuid();
      window.document.cookie = `m=${machineId};domain=.bodybuilding.com;path=/;max-age=${oneYear *
        20};secure;samesite=lax;`;
      pushEvent(
        'debug',
        'gtm',
        'debug',
        'm cookie fallback',
        null,
        {},
        {
          name: 'bb-analytics',
          version: '__VERSION__'
        }
      );
    }
  }

  return machineId;
}
