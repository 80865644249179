/**
 * Created By: btilford
 * Date: 12/5/17 3:38 PM
 */

// # Data Layer Event Functions
//
// This is both a module used inside of standalone `bb-analytics.js` script as well as the
// entry point (exports) for the AMD module used by other libraries like bb-ui `bb-analytics-amd.js`.
//
const dataLayer = window.dataLayer || [];

// Make the library version available to downstream projects.
export const version = '__VERSION__';

function sessionRemove(key) {
  try {
    if (window.sessionStorage && typeof window.sessionStorage.removeItem === 'function') {
      window.sessionStorage.removetem(key);
    }
  } catch (err) {
    // noop
  }
}

// ## sessionSet()
export function sessionSet(key, val) {
  try {
    if (val) {
      if (typeof val !== 'string') {
        val = JSON.stringify(val);
      }
      if (window.sessionStorage && typeof window.sessionStorage.setItem === 'function') {
        window.sessionStorage.setItem(key, val);
      }
    }
  } catch (err) {
    // noop
  }
}

// ## sessionGet()
export function sessionGet(key) {
  let val;
  try {
    if (window.sessionStorage && typeof window.sessionStorage.getItem === 'function') {
      let str = window.sessionStorage.getItem(key);
      if (str) {
        if (str.match(/^(\{|\[)/)) {
          val = JSON.parse(str);
        } else {
          val = str;
        }
      }
    }
  } catch (err) {
    // noop
  }
  return val;
}

// Keeps order of events within a hit.
let eventCounter = 0;

// ### Event Base Fields
// Each event will initialize these properties to undefined to prevent
// them from persisting between events on the dataLayer.
const baseFields = {
  bbZone1: undefined,
  bbZone2: undefined,
  bbZone3: undefined,
  bbZone4: undefined,
  isExit: undefined,
  exitType: undefined,
  position: undefined,
  promoType: undefined,
  promoId: undefined,
  resultCount: undefined,
  id: undefined,
  elementId: undefined,
  elementClass: undefined,
  elementType: undefined,
  eventType: undefined,
  name: undefined,
  href: undefined,
  x: undefined,
  y: undefined,
  width: undefined,
  height: undefined,
  itemSource: {
    version: '__VERSION__'
  },
  navigation: {
    target: undefined
  }
};
// ## pushEvent()
//
// This is the generic way to push an event onto the `dataLayer` It matches the
// API for `ga('send`, 'event', ...)` [reference](https://developers.google.com/analytics/devguides/collection/analyticsjs/events).
//
// It will capture 2 additional properties and add them to the `fields` object.
//
// 1. **fields.mark** -- The offset from page load the event occurred at. Uses `performance.mark()`.
// 2. **fields.eventNumber** -- A counter so the sequence of events on the page can be ordered in other systems. Or
// as a fallback when the performance API is not available.
export function pushEvent(eventName, category, action, label, value, fields, itemSource) {
  ++eventCounter;

  // This will reset the datalayer state for `bbEvent.fields` to what is in
  // `baseFields` + the current event's properties.
  fields = Object.assign({}, { itemSource: itemSource }, baseFields, fields);

  if (!fields.itemSource.name) {
    fields.itemSource.name = 'not-provided';
    fields.itemSource.stack = new Error('').stack;
  }

  fields.eventNumber = eventCounter;

  if (!fields.mark && typeof window.performance.mark === 'function') {
    const mark = eventName + category + eventCounter;
    window.performance.mark(mark);
    fields.mark = window.performance.getEntriesByName(mark)[0].startTime;
  }

  let event = {
    event: `bbEvent.${eventName}`,
    bbEvent: {
      event: eventName,
      category: category,
      action: action,
      label: label,
      fields: fields
    }
  };
  if (typeof value === 'number') {
    event.bbEvent.value = value;
  } else if (value) {
    window.console.warn(
      'Attempt to set value with type %s! Value must be a number! Stack: %s',
      typeof value,
      new Error().stack
    );
  }
  dataLayer.push(event);
  return event;
}

// ## validationError()
//
// Creates an event in the **validation** category.
export function validationError(event, action, label, value, fields, itemSource) {
  return pushEvent(`validation.${event}`, 'validation', action, label, value, fields, itemSource);
}

// ## loginStateChanged()
//
// Pushes an event with in the `user` category with event name of `loginStateChanged` onto the `dataLayer`.
//
// **Arguments**
// 1. **isLoggedIn** -- boolean used to set the event action `"login" or "logout"`
// 2. **status** -- Optional set the event label.
// 3. **loginMethod** -- Will **bbUserLoginMethod** on the `dataLayer`.
// 4. **fields** -- Additional event properties
// 5. **profile** -- The `profile/ga` object, will be used to populate additional user properties on the `dataLayer`.
export function loginStateChanged(
  isLoggedIn,
  status,
  loginMethod,
  fields,
  gaProfileData,
  itemSource
) {
  fields = fields || {};
  fields.bbUserLoginMethod = loginMethod;
  let event = pushEvent(
    'loginStateChanged',
    'user',
    isLoggedIn ? 'login' : 'logout',
    status || isLoggedIn ? 'signed in' : 'signed out',
    undefined,
    fields,
    itemSource
  );
  sessionRemove('bbUserLoginMethod');
  sessionRemove('bba.profile');
  if (isLoggedIn && loginMethod) {
    sessionSet('bbUserLoginMethod', loginMethod);
  }
  populateUserData(gaProfileData, dataLayer, isLoggedIn);
  return event;
}

// ## pushException()
//
// Pushes an exception onto the `dataLayer`. Matches [GA API](https://developers.google.com/analytics/devguides/collection/analyticsjs/exceptions)
export function pushException(msg, url, line, col, error) {
  let fileUrl,
    eventName,
    category,
    action,
    label,
    props = {};

  try {
    fileUrl = new UrlUtil(url || window.location.href);
  } catch (err) {
    // bad url nothing to look at
  }

  eventName = error ? error.constructor.name : 'exception';
  category = 'error';
  action = fileUrl ? `${fileUrl.href} :: (line:${line}, col:${col})` : 'exception';
  label = msg;
  props.line = line;
  props.col = col;
  if (error) {
    props.stack = error.stack;
  }

  if (line > 0 && label && label.length > 5) {
    // Try to filter out un-actionable errors.

    return dataLayer.push({
      event: `bbEvent.exception`,
      bbEvent: {
        eventType: 'exception',
        event: eventName,
        category: category,
        action: action,
        label: label,
        fields: props
      }
    });
  }
}

// ### GDPR Controlled User Properties
// See [DATA-339](http://jira/browse/DATA-339)
//
// #### Experience
// These properties should not be sent to GA when the user opts out of
// the experience bucket.
const experienceOnlyUserProps = [
  'bbUserId',
  'bbCommerceUserId',
  'bbUserHomeCountry',
  'bbUserHomeState',
  'bbUserHomeCity',
  'bbUserGoalsMain',
  'bbUserGoalsBodyFat',
  'bbUserGoalsPhysique',
  'bbUserGender',
  'bbUserMilitaryBranch',
  'bbUserJoinDate',
  'bbUserLastLogin',
  'bbCommerceOrderCount',
  'bbCommerceLastOrder'
];

// ## populateUserData()
//
// Populate profile from https://api.bodybuilding.com/profile/ga
//
// **Args**
// * **userProps** -- The response from [profile/ga](https://api.bodybuilding.com/profile/ga)
// * **dataLayer** -- Should be `window.dataLayer` unless running tests.
// * **isLoggedIn** -- Flag indicating if the user is logged in. When this is `false` all the
// user properties are cleared out.
export function populateUserData(userProps, dataLayer = [], isLoggedIn = false) {
  let merged = {};

  // Collect the latest version of the GDPR privacy settings from the `dataLayer`.
  dataLayer.forEach(item => (merged = Object.assign(merged, item)));
  let privacySettings = merged.privacySettings;

  // Attempt to figure out if the user logged in through a login page, modal, etc
  let loginMethod = sessionGet('bbUserLoginMethod');

  if (userProps && isLoggedIn) {
    userProps.event = 'bbEvent.userDataLoaded';
    userProps.bbUserLoggedInStatus = isLoggedIn;
    userProps.bbUserLoginMethod = loginMethod;

    // Format time properties to a relative time.
    if (userProps.bbUserLastLogin) {
      userProps.bbUserLastLogin = relativeTime(userProps.bbUserLastLogin);
    }
    if (userProps.bbUserJoinDate) {
      userProps.bbUserJoinDate = relativeTime(userProps.bbUserJoinDate);
    }

    if (userProps.subscriptionInfo && userProps.subscriptionInfo.allAccessSubscriptionExpiration) {
      userProps.subscriptionInfo.allAccessSubscriptionExpiration = relativeTime(
        userProps.subscriptionInfo.allAccessSubscriptionExpiration
      );
    }
  } else if (!isLoggedIn) {
    // ### When Not logged in
    // Clear out all the user properties. And remove the profile from session storage.
    userProps = {
      event: 'bbEvent.userDataReset',
      userId: undefined,

      bbUserLoggedInStatus: false,

      bbUserJoinDate: undefined,
      bbUserLastLogin: undefined,

      bbUserHomeCountry: undefined,
      bbUserHomeState: undefined,
      bbUserHomeCity: undefined,

      bbUserGoalsBodyFat: undefined,
      bbUserGoalsPhysique: undefined,

      bbUserMilitaryBranch: undefined,
      bbForumUserRep: undefined,
      bbUserGoalsMain: undefined,

      // Not currently being populated but we can still reset them...
      bbUserType: undefined,
      bbUserSubscriptionStatus: undefined,
      bbUserCurrencyCode: undefined,
      subscriptionInfo: undefined,
      bbUserLoginMethod: undefined,
      bbCommerceUserId: undefined
    };

    sessionRemove('bba.profile');
  }

  if (userProps) {
    // ### Remove GDPR Restricted Properties
    //
    // Remove all experience only user properties before adding to the
    // datalayer. [DATA-339](http://jira/browse/DATA-339)
    if (!privacySettings || !privacySettings.experience) {
      experienceOnlyUserProps.forEach(name => (userProps[name] = undefined));
    }

    dataLayer.push(userProps);
  }
  return userProps;
}

// ## pushTiming()
//
// Add a user timing to the `dataLayer`.
//
// **Args**
// 1. **category** -- The group/category for the event
// 2. **variable** -- The timing event name
// 3. **label** -- An optional label text
// 4. **value** -- An optional value. If no value is provided a performance mark will be created and used.
export function pushTiming(category, variable, label, value, itemSource) {
  if (!value && window.performance && typeof window.performance.mark === 'function') {
    let markName = `${category}.${variable}`;
    window.performance.mark(markName);
    value = window.performance.getEntriesByName(markName, 'mark');
    if (value.constructor.name.toLowerCase() === 'array') {
      value = value[value.length - 1];
    }
    value = value.startTime;
  }
  if (value) {
    let timing = {
      event: 'bbEvent.timing',
      bbTiming: {
        hitType: 'timing',
        category: category,
        variable: variable,
        value: value,
        label: label
      }
    };
    if (itemSource) {
      timing.itemSource = itemSource;
    }
    dataLayer.push(timing);
    return timing;
  }
}

let _markCounter = 0;
// #### _makeTimingName()
// Internal function to make a unique but repeatable name.
function _makeTimingName(measure, label) {
  return `${measure.name}[${measure.markIndex}][${++measure.counter}]${label ? '.' + label : ''}`;
}

// ### Internal tracking on whether or not performance related things are available.
let perf = {
  mark: window.performance && typeof window.performance.mark === 'function' ? true : false,
  measure: window.performance && typeof window.performance.measure === 'function' ? true : false
};

// ### _mark()
// Internal function to wrap `window.performance.mark()`
function _mark(markName) {
  let mark;
  if (perf.mark) {
    window.performance.mark(markName);
    mark = window.performance.getEntriesByName(markName, 'mark');
  }
  return mark;
}

// ## PerformanceMeasure
//
// This class will collect a length of time measurement and optionally report
// the offset of both the start and end marks.
export class PerformanceMeasure {
  constructor(category, name) {
    this.category = category;
    this.name = name;
    this.markIndex = ++_markCounter;
    this.counter = 0;
  }

  // ### PerformanceMeasure.start()
  //
  // Starts a measurement.
  //
  // Returns **this**
  start(label = '', pushMark = true) {
    this.startMark = _mark(_makeTimingName(this), label);
    if (this.startMark && this.startMark.constructor.name.toLowerCase() === 'array') {
      this.startMark = this.startMark[this.startMark.length - 1];
    }
    if (pushMark && this.startMark) {
      pushTiming(this.category, this.name + '.offset', label, this.startMark.startTime);
    }
    return this;
  }

  // ### PerformanceMeasure.measure()
  //
  // Takes a measurement (duration) beginning at the point in time [PerformanceMeasure.start()](#start-) was called
  // until the current time. Optionally you can also record a new mark..
  measure(label, pushMark = true) {
    let measure;
    if (window.performance && typeof window.performance.measure === 'function') {
      let endMarkName = _makeTimingName(this);
      let endMark = _mark(endMarkName);
      if (endMark && endMark.constructor.name.toLowerCase() === 'array') {
        endMark = endMark[endMark.length - 1];
      }
      if (endMark && this.startMark && perf.measure) {
        let measureName = _makeTimingName(this, label);
        window.performance.measure(measureName, this.startMark.name, endMark.name);
        measure = window.performance.getEntriesByName(measureName, 'measure');
        if (measure && measure.constructor.name.toLowerCase() === 'array') {
          measure = measure[measure.length - 1];
        }
        pushTiming(this.category, this.name + '.duration', label, measure.duration);
        if (pushMark) {
          pushTiming(this.category, this.name + '.offset', label, endMark.startTime);
        }
      }
    }
    return measure;
  }
}

// Helper that wraps a sync function call with a `PerformanceMeasure`.
//
// ```
// let result = measureExecution('app', 'bootstrap', () => angular.bootstrap(), 'begin', 'completed', true);
// ```
// The code above produces the following 2 offsets/marks and 1 measure/duration timing events in GA. All have the category **app**
// and the variable **bootstrap**.
//
// 1. `label = 'begin.offset'` -- The offset of when the bootstrap began.
// 2. `label = 'completed.offset'` -- The offset of when the bootstrap process completed.
// 3. `label = 'completed.duration'` -- The amount of time it took to call `angular.bootstrap()`. Or `completed.offset - begin.offset`.
//
export function measureExecution(
  category,
  name,
  fn,
  startLabel = 'start',
  endLabel = 'completed',
  includeMarks = true
) {
  const measure = new PerformanceMeasure(category, name);
  measure.start(startLabel, includeMarks);
  let output = fn();
  measure.measure(endLabel, includeMarks);
  return output;
}

// For triggering a page view in a single page app.
//
// **Params**
// * `pageName` -- **Required**
// * `pageType` -- _optional_ If not provided will continue using the last/initial `bbPageType` in the `dataLayer` array.
// * `pageSubType` -- _optional_ If not provided will continue using the last/initial `bbPageSubType` in the
// `dataLayer` array.
// * `props` -- Other props to either set or override what is currently in the `dataLayer` array.
//
// **Note**
// _If there is state in the current `dataLayer` array that should not be associated with this page view you need to
// delete it before calling this method **OR** pass it in the `props` param as `undefined`._
//
// **Example Delete:**
// ```
// bbAnalytics.pageView(
//      'myCategoryPageName',
//      'list',
//      'category',
//      {
//          bbMyPropToDelete: undefined,
//          bbAnotherPropToSet: 'xyz'
//      }
// );
// ```
//
// **Note bbSiteSection**
// _If for some reason `bbSiteSection` **was not set** on the initial page load (from the server) you can pass this
// property in with the `props` param. There really shouldn't be a scenario in a SPA that requires setting this property
// though. The site section shouldn't change between page views in a SPA and the server should know it's section._
export function pageView(pageName, pageType, pageSubType, props = {}, itemSource) {
  props.bbPageName = pageName;
  props.event = 'pageview';
  props.bbPageViewId = props.bbPageViewId || uuid();
  if (pageType) {
    props.bbPageType = pageType;
  }
  if (pageSubType) {
    props.bbPageSubType = pageSubType;
  }
  if (itemSource) {
    props.itemSource = itemSource;
  }

  dataLayer.push(props);
  return props;
}

// ## UrlUtil
//
// Polyfill for `URL` with additional utilities for URLs.
//
// TODO browserify/babel can't process amd modules in a way requirejs can use them as a library so this class
// was moved into this file.
export class UrlUtil {
  constructor(pathOrUrl) {
    if (pathOrUrl === undefined || pathOrUrl === null) {
      throw new Error(`Cannot construct URL from "${pathOrUrl}"`);
    }
    let url;
    if (!pathOrUrl.match(/^https?:\/\/.*/)) {
      const loc = window.location;
      // Assume relative URL
      let prefix;
      if (!pathOrUrl.match(/^\/.*/)) {
        prefix = `${loc.protocol}//${loc.hostname}/`;
      } else {
        prefix = `${loc.protocol}/`;
      }

      url = new URL(`${prefix}${pathOrUrl}`);
    } else {
      url = new URL(pathOrUrl);
    }
    // Object.keys does not return values
    // eslint-disable-next-line no-unused-vars
    for (let k in url) {
      if (typeof k !== 'function') {
        this[k] = url[k];
      }
    }

    this.domain = this.hostname.replace(/^(.+\.)*(\w.*)\.\w+$/, '$2');
    if (!this.domain) {
      this.domain = this.hostname; // internal stuff w/o TLD
    }
    this.fileName = (url.pathname || url.path || '/').replace(/.*\/(.+)\/?$/, '$1');
  }
}

// ## getClickZones(fromElement:Node)
//
// Collect a map of click zones `data-bb-zone[n]` by traversing up the DOM tree
// starting with `fromElement`. Traversal takes nearest ancestor for each zone
// and will stop traversal when zone 1 is reached or one of the document root elements
// is reached.
export function getClickZones(fromElement) {
  let zones = {};
  // ### Click Zones
  // Travel up the dom to find the zone and sub zone.
  let traverse = true;
  const stops = /^(body|html|head)$/;
  let parent = fromElement.localName.match(stops) ? fromElement : fromElement.parentNode;
  while (parent && traverse) {
    if (!(parent && parent.localName) || parent.localName.match(stops)) {
      traverse = false;
    }
    if (parent.dataset) {
      // Assign the zone if it's not already been assigned... closest parent to the element should win?
      Object.keys(parent.dataset)
        .filter(key => key.match(/^bbZone\d+$/))
        .forEach(zone => {
          zones[zone] = zones[zone] || parent.dataset[zone].toLowerCase();
          if (parent.dataset.bbPosition) {
            zones[`${zone}_position`] = parent.dataset.bbPosition.toLowerCase();
          }
        });
    }
    // If zone 1 has been set stop traversing up the DOM.
    traverse = !zones.bbZone1;

    parent = parent.parentNode;
  }
  return zones;
}

const sec = 1000;
const min = sec * 60;
const hour = min * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;
const year = month * 12;

// ## relativeTime(date:[Date|number])
//
// Output a relative time so that GA reporting can use buckets instead
// of timestamps.
//
// **Buckets**
// * N years ago
// * N years from now
// * 1 year ago
// * 1 year from now
// * N months ago
// * N months from now
// * 1 month ago
// * 1 month from now
// * N weeks ago
// * N weeks from now
// * 1 week ago
// * 1 week from now
// * N days ago
// * N days from now
// * 1 day ago
// * 1 day from now
// * today
export function relativeTime(date) {
  let result;
  let amount;
  const now = Date.now();
  const ts = typeof date === 'number' ? date : date.getTime();
  const difference = now - ts;
  const elapsed = Math.abs(difference);
  const direction = difference > 0 ? 'ago' : 'from now';
  let unit;
  if (elapsed >= year) {
    amount = Math.floor(elapsed / year);
    if (amount > 1) {
      unit = 'years';
    } else {
      unit = 'year';
    }
  } else if (elapsed >= month) {
    amount = Math.floor(elapsed / month);
    if (amount > 1) {
      unit = 'months';
    } else {
      unit = 'month';
    }
  } else if (elapsed >= week) {
    amount = Math.floor(elapsed / week);
    if (amount > 1) {
      unit = 'weeks';
    } else {
      unit = 'week';
    }
  } else if (elapsed >= day) {
    amount = Math.floor(elapsed / day);
    if (amount > 1) {
      unit = 'days';
    } else if (amount === 1) {
      unit = 'day';
    }
  }

  if (unit) {
    result = `${amount} ${unit} ${direction}`;
  } else {
    result = 'today';
  }
  return result;
}

// ## uuid()
// Generate a UUID
export function uuid() {
  let date = Date.now(),
    template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

  let uuid = template.replace(/[xy]/g, function(ch) {
    const rand = (date + Math.random() * 16) % 16 | 0;
    date = Math.floor(date / 16);
    return (ch === 'x' ? rand : (rand & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

// ## navigationEvent()
//
// This is just a helper with named args for the navigation props. This can be
// used when navigation is triggered in code rather than using a link.
export function navigationEvent(
  category,
  action,
  label,
  value,
  navigationTarget,
  navigationInitiator = 'script',
  fields,
  itemSource
) {
  fields.itemSource = itemSource || fields.itemSource || { name: 'not provided' };
  if (navigationTarget) {
    fields.navigation = {
      target: navigationTarget,
      initiator: navigationInitiator
    };
  } else if (!fields.navigation) {
    fields.navigation = {
      target: 'not provided',
      navigationInitiator
    };
  }

  return pushEvent('click', category, action, label, value, fields, itemSource);
}

// ## httpStatus(statusCode:int, fields:Object, itemSource:Object)
//
// This is just a helper with named args for http error pages 404, 500 etc.
export function httpStatus(statusCode, fields, itemSource) {
  return pushEvent('pageError', 'error', 'https.status', statusCode, null, fields, itemSource);
}
