/**
 * Created By: btilford
 * Date: 7/6/18 11:32 AM
 */

'use strict';
import { pushException, sessionGet, sessionSet, uuid } from './event';
import { getCookie, getMachineId } from './get-cookie';
import { moduleInfo } from './module-info';

// ## register()
//
// New method for initializing dataLayer for use in [bb-wrapper](http://github.body.prod/clientside/bb-wrapper).
//
// This sets up some common datalayer properties. Unlike <a href="./onload.js.html#register-">onload register()</a>
// it does not register event listeners or load user properties into the dataLayer. To
// get those you want to use [document-ready.js](./document-ready.js).
export function register() {
  window.dataLayer = window.dataLayer || [];
  const dataLayer = window.dataLayer;

  let dataLayerLoaded = {
    event: 'dataLayer-loaded',
    itemSource: moduleInfo
  };

  if (window.dataLayer.filter(obj => (obj.bbPageViewId ? obj : false)).length < 1) {
    window.console.warn('bb-analytics: App did not initialize dataLayer!');
    dataLayer.push({
      bbPageViewId: uuid(),
      itemSource: moduleInfo
    });
  }

  window.onerror = function globalErrorHandler(msg, url, line, col, error) {
    if (typeof msg === 'object') {
      pushException(msg.message, url, line, col, error || msg);
    } else {
      pushException(msg, url, line, col, error);
    }
  };

  let tabId = sessionGet('bbPageTabId');
  if (!tabId) {
    tabId = uuid();
    sessionSet('bbPageTabId', tabId);
  }

  let isLoggedIn = false;
  if (getCookie('lgn') === '1') {
    isLoggedIn = true;
  } else if (sessionGet('bba.profile') || sessionGet('bbUserLoginMethod')) {
    isLoggedIn = true;
  }

  dataLayer.push({
    bbPageTabId: tabId,
    bbUserMachineId: getMachineId(),
    itemSource: moduleInfo,
    bbUserLoggedInStatus: isLoggedIn
  });

  // ### DATA-273 [GA: Events firing before pageview](http://jira/browse/DATA-273)
  // We want to make sure we don't fire any events before gtm.js loads and triggers
  // the pageview event.
  let waitForPageViewEvent = window.setInterval(() => {
    if (dataLayer.filter(item => item.event && item.event === 'gtm.js').length > 0) {
      window.clearInterval(waitForPageViewEvent);
      dataLayer.push(dataLayerLoaded);
    }
  }, 500);
}
