/**
 * Created By: btilford
 * Date: 7/6/18 11:28 AM
 */
import { register } from './bb/analytics/head-onload';

export const bbAnalytics = {
  version: '__VERSION__'
};

window.bbAnalytics = bbAnalytics;

register();
